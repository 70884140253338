import { configureStore } from "@reduxjs/toolkit"
import { peepoApi } from "../services/peepo"
import formReducer from "./formSlice"
import guidReducer from "./guidSlice"
import participationPageReducer from "./participationPageSlice"

const createStore = () =>
  configureStore({
    reducer: {
      form: formReducer,
      guid: guidReducer,
      participationPage: participationPageReducer,
      [peepoApi.reducerPath]: peepoApi.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat([
        peepoApi.middleware,
      ]),
  })

type ConfiguredStore = ReturnType<typeof createStore>
type StoreGetState = ConfiguredStore["getState"]
export type RootState = ReturnType<StoreGetState>
export type AppDispatch = ConfiguredStore["dispatch"]

export default createStore
