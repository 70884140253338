import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ParticipationPageState {
    successfullySubmitted: boolean
}

const initialState: ParticipationPageState = {
    successfullySubmitted: false,
}

export const participationPageSlice = createSlice({
  name: 'participationPage',
  initialState,
  reducers: {
    setParticipationSuccessful: (state, action: PayloadAction<boolean>) => {
      state.successfullySubmitted = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setParticipationSuccessful } = participationPageSlice.actions

export default participationPageSlice.reducer