import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import FormStep from '../enums/FormStep'
import IPeepo from '../interfaces/IPeepo'

export interface FormState {
    currentStep: FormStep,
    peepo: IPeepo
}

const initialState: FormState = {
    currentStep: FormStep.ONE,
    peepo: {
      title: '',
      location: '',
      note: '',
      name: '',
      email: '',
      generationDateTime: undefined,
    }
}

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormStep: (state, action: PayloadAction<FormStep>) => {
      state.currentStep = action.payload
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.peepo.title = action.payload
    },
    setLocation: (state, action: PayloadAction<string>) => {
      state.peepo.location = action.payload
    },
    setNote: (state, action: PayloadAction<string>) => {
      state.peepo.note = action.payload
    },
    setName: (state, action: PayloadAction<string>) => {
      state.peepo.name = action.payload
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.peepo.email = action.payload
    },
    setGenerationDateTime: (state, action: PayloadAction<Date>) => {
      state.peepo.generationDateTime = action.payload
    },
    resetFormState: () => initialState
  },
})

// Action creators are generated for each case reducer function
export const { setFormStep, setTitle, setLocation, setNote, setName, setEmail, setGenerationDateTime, resetFormState } = formSlice.actions

export default formSlice.reducer