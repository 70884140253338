import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import IPeepo from '../interfaces/IPeepo'

export interface FormState {
    currentGuid: string
    currentPeepo?: IPeepo
}

const initialState: FormState = {
    currentGuid: '',
    currentPeepo: undefined
}

export const guidSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setGuid: (state, action: PayloadAction<string>) => {
      state.currentGuid = action.payload
    },
    setPeepo: (state, action: PayloadAction<IPeepo>) => {
      state.currentPeepo = action.payload
    },
    resetPeepo: (state) => {
      state.currentPeepo = undefined
    }
  },
})

// Action creators are generated for each case reducer function
export const { setGuid, setPeepo, resetPeepo } = guidSlice.actions

export default guidSlice.reducer