import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import ApiConstants from "../constants/ApiConstants"
import Constants from "../constants/Constants"
import IAddFriendByGuid from "../interfaces/IAddFriendByGuid"
import IPeepo from "../interfaces/IPeepo"

// peepo shares the same backend as the location cheat sheet data, 
// just with some different typings and some additional data
export const peepoApi = createApi({
  reducerPath: "peepoApi",
  baseQuery: fetchBaseQuery({ baseUrl: Constants.API_URL }),
  tagTypes: ["Peepo"],
  endpoints: build => ({
    addPeepo: build.mutation<
      IPeepo,
      IPeepo
    >({
      query(body) {
        return {
          url: ApiConstants.CREATE_PEEPO,
          method: "POST",
          body,
        }
      },
      transformResponse: (rawResult: { result: IPeepo } ) => {
        return rawResult.result
      },
    }),
    getPeepoByGuid: build.query<
      IPeepo,
      string
    >({
      query(guid) {
        return {
          url: `${ApiConstants.GET_PEEPO_BY_GUID}?guid=${guid}`,
          method: "GET",
        }
      },
      transformResponse: (rawResult: { result: IPeepo } ) => {
        return rawResult.result
      },
    }),
    addFriend: build.mutation<
      boolean,
      IAddFriendByGuid
    >({
      query(body) {
        return {
          url: ApiConstants.PARTICIPATE_BY_GUID,
          method: "POST",
          body,
        }
      },
      transformResponse: (rawResult: { success: boolean } ) => {
        return rawResult.success
      },
    }),
  }),
})

export const { useAddPeepoMutation, useGetPeepoByGuidQuery, useAddFriendMutation } =
  peepoApi
